import React from 'react';
import Header from "./components/Header";
import Content from "./components/Content";
import Footer from "./components/Footer";
import GotoTop from './components/GotoTop';
const Home = () => {
  return (
    <>
       <Header />
        <Content />
        <Footer />
    <GotoTop />
    </>
  )
}

export default Home
