import React, { useRef } from "react";
import { FaTimes } from "react-icons/fa";
import "./../scss/_modal.scss";
import emailjs from 'emailjs-com';

const ContactForm = ({ showModal, setShowModal }) => {
  const form = useRef();
    const [formStatus, setFormStatus] = React.useState('Submit')
    const sendEmail = (e) => {
        e.preventDefault();
        setFormStatus('Submitting...');
        emailjs.sendForm('service_8ynhxad', 'template_bv116vo', form.current, 'IK6YkSEctORWcSMyp')
            .then((result) => {
                console.log(result.text);
                setFormStatus('Submit');
                document.getElementById("create-course-form").reset();
            }, (error) => {
                console.log(error.text);
            });
    }
  return (
    <>
      {showModal ? (
        <div className="modal-main">
          <nav className="contactnav p3 pl5 d-flex">
            <h3 className="text-white">Contact form</h3>
            <FaTimes
              className=""
              color="white"
              cursor="pointer"
              onClick={() => setShowModal((prev) => !prev)}
            />
          </nav>
          <form id="create-course-form" ref={form} onSubmit={sendEmail} className="modal-content">

<div className='form-group' htmlFor="name">
    <input type="text" className='form-control tfield1' placeholder='Name' id="name" name="user_name" required />
</div>
<div className='form-group' htmlFor="email">
    <input type="email" className='form-control tfield2' placeholder='Email' name="user_email" id="email" required />
</div>
<div className='form-group' htmlFor="subject">
    <input type="text" className='form-control tfield3' placeholder='Subject' name="user_subject" id="subject" required />
</div>
<div className='form-group' htmlFor="message">
    <textarea cols="10" className='form-control tfield4' rows="3" placeholder='Type Your Message' name="message" id="message" required></textarea>
</div>
<div className='form-group text-right'>
    <button className='btn btn-black m-0'>{formStatus}</button>

</div>
</form>
         
        </div>
      ) : null}
    </>
  );
};

export default ContactForm;
