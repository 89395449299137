import React from "react";
import "./../scss/_benifits.scss";
const Benifits = () => {
  return (
    <section className="p-0 " id="whyus">
      <div className="row w-100  m-0 benifit-container">
        <div className="col-md-5 offset-sm-1">
           <div className="benifits">
              <h5>My Experience</h5>
              <h2>WHY WE'RE BEST</h2>
           
            <div className="row text-center">
          <div className="col-sm-6 col-6">
            <div className="col-12">
              <img src={require("./../assets/images/we-best-icon1.png")} />
              <h4>
               13+ Years of <br />
                experience
              </h4>
            </div>
            <div className="col-12">
              <img src={require("./../assets/images/we-best-icon2.png")} />
              <h4>
                The best <br />
                User interfaces
              </h4>
            </div>
          </div>
          
          <div className="col-md-6 col-sm-6 col-6">
            <div className="col-12">
              <img src={require("./../assets/images/we-best-icon3.png")} />
              <h4>
               180+ Completed
                <br /> projects
              </h4>
            </div>
            <div className="col-12">
              <img src={require("./../assets/images/we-best-icon4.png")} />
              <h4>
                Quick smooth <br /> web Development
              </h4>
            </div>
          </div>
        </div>
        </div>
        </div>
        <div className="col-md-6 p-0">
          <img src={require("./../assets/images/whyus.jpg")}></img>
        </div>
      </div>
     
    </section>
  );
};

export default Benifits;
