import React from 'react';
import './../scss/_showcase.scss';
import "photoswipe/dist/photoswipe.css";
//import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";

const content = () => {
  console.log('Click happened');
}

const WorkShowcase = () => {
  return (
    <>
      <section className=' showcase-bg' id="work">

        <div className='container'>
          <h5>My Work</h5>
          <h2>Work Showcase</h2>
          <div className='container gallerypage'>
         
              <a href='https://aashitajain.com/' target="_blank"> <img src={require("./../assets/images/1.jpg")} className='home-gallery' /></a>
            
              <a href='https://www.citizencop.org/' target="_blank"> <img src={require("./../assets/images/7.jpg")} className='home-gallery' /></a>
            
              <a href='https://www.shishukunj.in/' target="_blank"> <img src={require("./../assets/images/3.jpg")} className='home-gallery' /></a>
            
          
              <a href='https://reclaim7.com/' target="_blank"> <img src={require("./../assets/images/4.jpg")} className='home-gallery' /></a>
            
              <a href='https://www.infocratsweb.com/' target="_blank"> <img src={require("./../assets/images/5.jpg")} className='home-gallery' /></a>
           
              <a href='http://projects.stagingsoftware.com/quacito_new/' target="_blank"> <img src={require("./../assets/images/6.jpg")} className='home-gallery' /></a>
          
              <a href='https://rakesh-jain.com/' target="_blank"> <img src={require("./../assets/images/2.jpg")} className='home-gallery' /></a>
           
              <a href='https://www.omnamkeen.com/' target="_blank"> <img src={require("./../assets/images/8.jpg")} className='home-gallery' /></a>
          
          {/* <Gallery>
            <Item original={require("./../assets/images/1.jpg")} onClick={content} thumbnail={require("./../assets/images/1.jpg")} width="1024" height="1024">
              {({ ref, open }) => (
                <img src={require("./../assets/images/1.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/7.jpg")}
              thumbnail={require("./../assets/images/7.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/7.jpg")} ref={ref} onClick={open} className='home-gallery' />

              )}
            </Item>
            <Item
              original={require("./../assets/images/3.jpg")}
              thumbnail={require("./../assets/images/3.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/3.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/4.jpg")}
              thumbnail={require("./../assets/images/4.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/4.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/5.jpg")}
              thumbnail={require("./../assets/images/5.jpg")}
              width="1024"
              height="768">
              {({ ref, open }) => (
                <img src={require("./../assets/images/5.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/6.jpg")}
              thumbnail={require("./../assets/images/6.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/6.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/2.jpg")}
              thumbnail={require("./../assets/images/2.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/2.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
            <Item
              original={require("./../assets/images/8.jpg")}
              thumbnail={require("./../assets/images/8.jpg")}
              width="1024"
              height="768" >
              {({ ref, open }) => (
                <img src={require("./../assets/images/8.jpg")} ref={ref} onClick={open} className='home-gallery' />
              )}
            </Item>
          </Gallery> */}
        </div>
</div>
      </section>
    </>
  )
}

export default WorkShowcase

