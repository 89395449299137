import React from 'react';
import './../scss/_about.scss';

const About = () => {
  return (
    <>
      <section id="about">
        <div className='container'>
          <div className='aboutus'>

            <div className='row align-items-center'>
              <div className='col-sm-6'>
                <h5>I am expert in</h5>
                <h2>Who I'm</h2><p> Any website, in my opinion, ought to be responsive to different mobile, tablet, and desktop resolutions in order to provide amazing end users with each device.
                </p>
                <p>I have knowledge of Google's Material Design and Bootstrap. </p>
                I can assist you build a strong brand recognition in the marketplace as well as periodically bolster your online presence.

              </div>
              <div className='col-sm-6'><img src={require("./../assets/images/about.jpg")} /></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
