import React, {useState} from "react";
import "./../scss/_contact.scss";
import { rubberBand } from "react-animations";
import Radium, { StyleRoot } from "radium";
import ContactForm from "./ContactForm";
const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
 
  const styles = {
    rubberband: {
      animation: "x 1s 5s",
      animationName: Radium.keyframes(rubberBand, "rubberband"),
    },
  };
  return (
    <section className="contact-bg d-flex" id="contact">
      <div className="container">
       
        <div className="row">
          <div className="col-md-6">
            <h2>QUICK CONNECT</h2>
          </div>
          <div className="col-md-6">
            <StyleRoot>

            {/* <a href="shraddha-cv.pdf" download="shraddha-cv.pdf"> */}
            <button className="btn btn-black m-2" style={styles.rubberband}   onClick={openModal}>
                LET'S GET STARTED
              </button>
              {/* </a>  */}
              <ContactForm showModal={showModal} setShowModal={setShowModal} />
            </StyleRoot>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
