import React from "react";
import "./../scss/_skills.scss";
import PercentBar from "./PercentBar";
import { FaDownload } from "react-icons/fa";

const Skills = () => {
  const bgColor = "#7600d8";
  return (
  
    <section className="skills-bg" id="skills">
      <div className="container">
        <div className="row align-items-center">
        <div className="col-md-6">
          <div className="d-flex">
            <h5>Skills</h5>
           
          </div>
          <h2>My skills</h2>
          <div><ul>
            <li>UI/UX Design</li>
            <li>Responsive Web Design</li>
            <li>Web Portal Design</li>
            <li>Dynamic Website Design</li>
            <li>Custom web design & development</li>
            <li>Graphic Design/ Logo Design</li>
            <li>Brochures Design</li>
            <li>App Design</li>
          </ul>
            </div>
            <a href="shraddha-cv.pdf" download="shraddha-cv.pdf">
              <div className="site-btn">
               My Work
                <span> <FaDownload cursor="pointer" className="ml4" download="Resume" />
                </span>
              </div></a> 
        </div>
        <div className="col-md-6 ">
          <PercentBar bgcolor={bgColor} completed={60} label={"Angular JS"} />
          <PercentBar bgcolor={bgColor} completed={50} label={"React JS"} />
          <PercentBar bgcolor={bgColor} completed={80} label={"Wordpress"} />
          <PercentBar bgcolor={bgColor} completed={90} label={"Bootstrap"} />
        </div>
      </div>
      </div>
    </section>
  );
};

export default Skills;
