import { isVisible } from '@testing-library/user-event/dist/utils';
import React, {useEffect, useState} from 'react';
import { FaArrowCircleUp } from "react-icons/fa";

const GotoTop = () => {
    const [isVisible, setIsVisible] = useState(false);
    const GoToBtn = () =>{
        window.scrollTo({top:0, left:0, behavior:"smooth"})
    };
    const listenToScroll = () =>{
        let heightToHidden = 250;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winScroll > heightToHidden){
            setIsVisible(true);
        }else{
            setIsVisible(false)
        }
    };
useEffect(() =>{
    window.addEventListener("scroll", listenToScroll)
}, []);
  return (
    <>
    {isVisible && (
      <div className='top-btn' onClick={GoToBtn}>
      
                    <FaArrowCircleUp />
           
      </div>

    )}


     





    </>
  )
}

export default GotoTop
