import React from "react";
import "./../scss/_offers.scss";

const Offerings = () => {
  return (
    <section className="offer-bg">
      <div className="container">
        <h5 className="">Our services</h5>
        <h2 className="mb15">My Offered Services</h2>
        <div className="row text-center">
          <div className="col-md-3 col-6">
            <img src={require("./../assets/images/we-offer-icon1.png")}></img>
            <h4 > Creative and<br/> Unique Design</h4>
          </div>
          <div className="col-md-3 col-6">
            <img src={require("./../assets/images/we-offer-icon2.png")}></img>
            <h4>Quality Coding<br/> Development</h4>
          </div>
          <div className="col-md-3 col-6">
            <img src={require("./../assets/images/we-offer-icon3.png")}></img>
            <h4 >Latest<br/> Technology</h4>
          </div>
          <div className="col-md-3 col-6">
            <img src={require("./../assets/images/we-offer-icon4.png")}></img>
            <h4 >Excellent<br/> Support</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offerings;
