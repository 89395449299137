import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import "./../scss/_footer.scss";


const Footer = () => {
 
  return (
    <section className="footer-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-8 text-left">
            LET'S WORK TOGETHER. JUST DROP ME A LINE -
             <a href="mailto:shraddha00911@gmail.com"><span className="glow">shraddha00911@gmail.com</span></a>
          </div>
          {/* <div className="col-md-4 text-right">
            <FaFacebook color="#7600d8" cursor="pointer" className="ml4" />
            <FaTwitter color="#7600d8" cursor="pointer" className="ml4" />
            <FaInstagram color="#7600d8" cursor="pointer" className="ml4" />
            <FaLinkedin color="#7600d8" cursor="pointer" className="ml4" />
          </div> */}
          </div>
      </div>
    </section>
  );
};

export default Footer;
