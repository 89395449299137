import React from 'react';
import GalleryHeader from '../components/GalleryHeader';
import Footer from '../components/Footer';
import "./../scss/_showcase.scss";
import GotoTop from '../components/GotoTop';

const Gallery = () => {
  return (
    <>
      <GalleryHeader />
      <section>
        <div className='container gallerypage'>
          <h1>Work</h1>
          <div className='row'>
            <div className='col-sm-4'>
              <a href='https://aashitajain.com/' target="_blank"> <img src={require("./../assets/images/1.jpg")} className='home-gallery' /></a>
            </div>
            <div className='col-sm-4'>
              <a href='https://www.citizencop.org/' target="_blank"> <img src={require("./../assets/images/7.jpg")} className='home-gallery' /></a>
            </div>
            <div className='col-sm-4'>
              <a href='https://www.shishukunj.in/' target="_blank"> <img src={require("./../assets/images/3.jpg")} className='home-gallery' /></a>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <a href='https://reclaim7.com/' target="_blank"> <img src={require("./../assets/images/4.jpg")} className='home-gallery' /></a>
            </div>
            <div className='col-sm-4'>
              <a href='https://www.infocratsweb.com/' target="_blank"> <img src={require("./../assets/images/5.jpg")} className='home-gallery' /></a>
            </div>
            <div className='col-sm-4'>
              <a href='http://projects.stagingsoftware.com/quacito_new/' target="_blank"> <img src={require("./../assets/images/6.jpg")} className='home-gallery' /></a>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <a href='https://rakesh-jain.com/' target="_blank"> <img src={require("./../assets/images/2.jpg")} className='home-gallery' /></a>
            </div>
            <div className='col-sm-4'>
              <a href='https://www.omnamkeen.com/' target="_blank"> <img src={require("./../assets/images/5.jpg")} className='home-gallery' /></a>
            </div>
           
          </div>
        </div>



        
      </section>
      <Footer />
      <GotoTop />
    </>
  )
}

export default Gallery;
