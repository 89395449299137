import "./App.scss";
import "./scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { bounce, slideInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { BsFillChatRightTextFill } from "react-icons/bs";
import ContactForm from "./components/ContactForm";
import { useState } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import Gallery from "./pages/Gallery";
import Home from "./Home";

function App() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  const styles = {
    slideInUp: {
      animation: "x 1s",
      animationName: Radium.keyframes(slideInUp, "slideInUp"),
    },
  };
  return (
    <StyleRoot>
      {/* <div>{show}</div> */}
      <div className="App">
       
        <a className="whatsapp_float">
          <BsFillChatRightTextFill 
            color="white"
            className="whatsapp-icon"
            onClick={openModal}
          />
        </a>
        {/* <div id="main"></div> */}

        <ContactForm showModal={showModal} setShowModal={setShowModal} />
      
         <Routes>
         <Route exact path='/' Component={Home}></Route>
         <Route exact path="/Gallery" element={<Gallery />} />
          </Routes>
        </div> 
    
    </StyleRoot>
  );
}

export default App;
