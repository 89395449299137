import React from "react";

const PercentBar = (props) => {
  const { bgcolor, completed, label } = props;
  const wrapperStyles = {
    backgroundColor: "#393636",
    height: 100,
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginBottom: 25,
  };
  const containerStyles = {
    height: 15,
    width: "80%",
    backgroundColor: "black",
    borderRadius: 50
  };

  const fillerStyles = {

    width: `${completed}%`

  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };
  return (
    <div className="percentContainer">
      <label>{label} </label>
      <div className="scrollbar">
        <div className="fillerStyles" style={fillerStyles}>
          <div style={labelStyles}><div className="">{`${completed}%`}</div> </div>
        </div>
      </div>
    </div>
  );
};

export default PercentBar;
