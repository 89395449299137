import React from 'react';
import Benifits from './Benifits';
import Contact from './Contact';
import Offerings from './Offerings';
import Skills from './Skills';
import WorkShowcase from './WorkShowcase';
import About from './About';

const Content = () => {
  return <div>
      <About />
      <Offerings />
      <Skills />
      <WorkShowcase />
      <Benifits />
      <Contact />
  </div>;
};

export default Content;
