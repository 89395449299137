import React, {useState} from "react";
import "./../scss/_header.scss";
import { FaDownload, FaFacebook, FaInstagram, FaTwitter,  FaHtml5, FaCss3, FaAngular, FaReact, FaWordpress, FaOpencart } from "react-icons/fa";
import { bounce, slideInUp } from "react-animations";
import Radium, { StyleRoot } from "radium";
import { Col, Container, Navbar, NavbarBrand, Row, Nav, NavDropdown, NavLink } from "react-bootstrap";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";


const GalleryHeader = () => {


  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  

  return (
    <>
      <section className="hero-image">
        <div className="col-md-2 offset-sm-10 col-8 offset-4 d-sm-none d-block">
          <div className="top-contact ">
            {/* <div className="left">
              <img src={require("./../assets/images/contact-icon.png")} />
            </div> */}
            <div className="right">
            Reach me at<span> +91-9039022777</span>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
        <Container>
          <Row>
            <Col>
              <Navbar className="navbar-dark" expand="sm">
                <div className="container" >
                  <Navbar.Brand href="/"><span>WW</span>Designs</Navbar.Brand>
                  <NavbarToggle aria-controls="my-nav"></NavbarToggle>
                  <Navbar.Collapse id="my-nav">
                    <Nav className="ms-auto">
                      <NavLink href="/#about">About</NavLink>
                      <NavLink href="/#skills">Skills</NavLink>
                      {/* <NavDropdown title="Gallery" id="my-nav">
                                <NavDropdown.Item href='/benifit'>Services 1</NavDropdown.Item>
                                <NavDropdown.Item>Services 1</NavDropdown.Item>
                                <NavDropdown.Item>Services 1</NavDropdown.Item>
                            </NavDropdown> */}
                      <NavLink href="/gallery">Work</NavLink>
                      <NavLink href="/#whyus">Why Choose Us</NavLink>
                      <NavLink href="/#contact">Contact</NavLink>
                    </Nav>
                    <Navbar.Text>
                      <div className="top-contact d-none d-sm-block">
                        {/* <div className="left">
                          <img src={require("./../assets/images/contact-icon.png")} />
                        </div> */}
                        <div className="right">
                        Reach me at <span> +91-9039022777</span>
                        </div>
                      </div>
                    </Navbar.Text>
                  </Navbar.Collapse>
                </div>
              </Navbar>
            </Col>
          </Row>
        </Container>
        <div className="container banner" >

          <div className="row">
            <div className="col-sm-6">
              <h1>
                Hello! This is <br />
                Shraddha
              </h1>
              <h2>
                Frontend Developer
              </h2>
              {/* <h3>Looking for a full time job</h3> */}
             <a href="shraddha-cv.pdf" download="shraddha-cv.pdf">
              <div className="site-btn">
              My Work
                <span> <FaDownload cursor="pointer" className="ml4" download="Resume" />
                </span>
              </div></a> 
            </div>
            <div className="col-sm-6">
              <div className="round-image">
                <img src={require("./../assets/images/gallery-frame.jpg")} />
                <div className="social-link">
                  <a href='https://www.google.com/' >
                    <FaHtml5 color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaCss3 color="white" cursor="pointer" />
                  </a>
                  <a href='#'>
                    <FaAngular color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaReact color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaWordpress color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaOpencart color="white" cursor="pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="clearfix"></div>
    
      {/* <div className="col-sm-12 text-right">
      <div className="img-text-wrapper">
        <div className="container">
          <div className="info-wrapper col-md-3 offset-sm-9">
            <div className="top-contact d-flex align-item-center justify-content-center">
              <div className="left">
                <img src={require("./../assets/images/contact-icon.png")} />
              </div>
              <div className="right">
                CALL ANYTIME<br /><span> +91-9039022777</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row header-content">
            <div className=" col-md-6">
              <div className="logo-wrapper">
                <h1>
                  Hello! I'm <br />
                  Juhi Upreti
                </h1>
                <h2>
                  Frontend developer specializing in Angular, Data Science
                </h2>
                <div className="site-btn">
                  Get resume
                  <span> <FaDownload cursor="pointer" className="ml4" download="Resume" />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="round-image">
                <img src={require("./../assets/images/image1.jpg")} />
                <div class="social-link">
                  <a href='#' >
                    <FaFacebook color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaTwitter color="white" cursor="pointer" />
                  </a>
                  <a href='#'>
                    <FaInstagram color="white" cursor="pointer" />
                  </a>
                  <a href='#' >
                    <FaLinkedin color="white" cursor="pointer" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};
export default GalleryHeader;
